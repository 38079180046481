<template>
  <div class="mpBody">
    <div class="loading" v-if="!loading">
      <button v-if="schemeUrl" class="btn" @click="openMp">打开小程序</button>
      <h1 v-else>未获取到微信小程序SchemeUrl</h1>
    </div>
    <div class="loading" v-else>
      <h1>加载中...</h1>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MP",
  data() {
    return {
      loading: true,
      schemeUrl: null,
    };
  },
  mounted() {
    axios({
      baseURL: process.env.VUE_APP_MP,
      url: '/app/login/generateWxSchemeUrl',
      method: "get",
    }).then((res) => {
      this.loading = false;
      this.schemeUrl = res.data;
      window.location.href = res.data;
    }).catch(() => {
      this.loading = false;
    });
  },
  methods: {
    openMp() {
      window.location.href = this.schemeUrl;
    }
  }
};
</script>

<style scoped lang="scss">
.mpBody {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .btn {
    padding: 4px 12px;
  }
}
</style>
